import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    text: "",
    color: "",
    timeout: "",
    loading: false,
    token: "",
    userDetails: {},
    permissions: {},
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    getLayoutPage(state) {
      return state.layoutPage;
    },
    token(state) {
      return state.token;
    },
    userDetails(state) {
      return state.userDetails;
    },
    permissions(state) {
      return state.permissions;
    },
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.timeout = payload.timeout;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_USER(state, payload) {
      localStorage.setItem("token", payload.token);
      state.token = payload.token;
    },
    SET_USER_DETAILS(state, payload) {
      state.permissions = payload.permissions;
      state.userDetails = payload;
    },
    REMOVE_USER(state) {
      localStorage.removeItem("token");
      state.token = "";
      router.push("/auth/login");
    },
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    loading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    logout({ commit }) {
      commit("REMOVE_USER");
    },
    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    setUserDetails({ commit }, payload) {
      commit("SET_USER_DETAILS", payload);
    },
  },
  modules: {},
});
