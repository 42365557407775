<template>
  <div class="upload-image">
    <img :src="newImage ? newImage : url" alt="" />
    <div class="upload-btn cursor-pointer">
      <v-icon dark color="white">mdi-upload</v-icon>
      <span class="font-15 white--text cursor-pointer">Upload Image</span>
      <v-file-input
        accept="image/*"
        label="File input"
        class="file-upload"
        @change="newImageHandler"
        hide-details="true"
        :multiple="multiple"
      ></v-file-input>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    newImage: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default:
        "https://colorcenter.com.tr/wp-content/uploads/2016/11/default_image_01-1024x1024-570x428.png",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    newImageHandler(val) {
      // check image size
      if (val.size > 2049892) {
        this.$store.dispatch("showSnack", {
          text: "image size must not be more than 2 MB!",
          color: "error",
        });
        return;
      }
      this.$emit("newImageHandler", val);
    },
  },
};
</script>

<style lang="scss">
.upload-image {
  width: 100%;
  height: 250px;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .v-file-input__text {
    cursor: pointer;
  }

  .upload-btn {
    position: absolute;
    bottom: 20px;
    left: 20px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    z-index: 9;

    .file-upload {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 9;
      margin: 0px !important;
      padding: 0px !important;

      label {
        display: none !important;
      }
    }
  }
}
</style>
