<template>
  <div class="base-input base-select" :style="{ width: width }">
    <v-select
      :items="items"
      outlined
      ref="select"
      :placeholder="placeholder"
      dense
      background-color="lightbg"
      :hide-details="hideDetails"
      :item-value="itemValue"
      :item-text="itemText"
      :value="value"
      :rules="rules"
      :multiple="multiple"
      :clearable="clearable"
      @click:clear="$emit('clear')"
      @input="$emit('input', $event)"
      :chips="selectAll"
      persistent-hint
    >
      <template v-if="slotOptions">
        <div>item</div>
      </template>
      <template v-slot:prepend-item v-if="selectAll">
          <v-list-item ripple @click="$emit('toggleAllPermissions')">
            <v-list-item-content>
              <v-list-item-title> Select All </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:selection="{ item, index }" v-if="selectAll">
        <v-chip v-if="index === 0">
          <span>{{ item.name }}</span>
        </v-chip>
        <span
          v-if="index === 1"
          class="grey--text text-caption"
        >
          (+{{ value.length - 1 }} others)
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    itemText: {
      type: String,
      default: "name",
    },
    width: {
      type: String,
      default: "200px",
    },
    value: {
      validator: () => true,
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || ""],
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    slotOptions: {
      type: Boolean,
      default: false,
    },
  },
  mounted(){
    this.$refs.select.lastItem = 200;
  }
};
</script>

<style lang="scss">
.base-select {
  fieldset {
    border: 0px !important;
    background: #e5ebff6c;
  }
  .error--text {
    .v-input__slot {
      fieldset {
        border: 1px solid #e44358 !important;
      }
    }
  }
}
</style>
