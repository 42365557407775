import axios from "axios";
import store from "../store/index";

export const fetchPermissions = async () => {
  let { data } = await axios.get(`backend/permissions`);
  return data;
};

export const fetchRoles = async () => {
  let { data } = await axios.get(`backend/roles`);
  return data;
};

export const fetchUsers = async (locationID) => {
  if (!locationID) locationID = 0;
  let { data } = await axios.get(`backend/users?location_id=${locationID}`);
  return data;
};

export const fetchUserByID = async (id) => {
  let { data } = await axios.get(`backend/users/${id}`);
  return data;
};

export const fetchCurrentUser = async () => {
  let { data } = await axios.get(`backend/getCurrentUser`);
  return data;
};

export const createUser = async (data) => {
  let res = await axios.post(`backend/users`, data);
  if (!res.error && res.data) {
    let response = await fetchUsers();
    return { data: response, success: true };
  } else {
    return { err: "something going wrong", success: false };
  }
};

export const updateUser = async (data) => {
  delete data.email
  let res = await axios.patch(`backend/users/${data.id}`, data);

  if (!res.error && res.data) {
    let response = await fetchUsers();
    return { data: response, success: true };
  } else {
    return { err: "something going wrong", success: false };
  }
};

export const deleteUser = async (id) => {
  let res = await axios.delete(`backend/users/${id}`);

  if (!res.error && res.data.status) {
    let response = await fetchUsers();
    store.dispatch("showSnack", {
      text: "User Deleted Successfully",
      color: "success",
    });
    return { data: response, success: true };
  } else {
    return { err: "something going wrong", success: false };
  }
};
