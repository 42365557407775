import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store/index";
import axios from "axios";
import { fetchCurrentUser } from "../services/Users";

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
  base: process.env.BASE_URL,
});

router.beforeEach(async (to, from, next) => {
  const isLogin = localStorage.getItem("token");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let permissions = {};
    permissions = store.getters.permissions;

    if (permissions && Object.keys(permissions).length == 0) {
      let res = await fetchCurrentUser();
      await store.dispatch("setUserDetails", res);
      permissions = res.permissions;
    }
    if (isLogin) {
      if (permissions["role.admin"]) {
        if (!to.meta.requiresPermission) {
          if(to.name == 'Home'){
            next("/statistics");
          }else{

            next();
          }
        } else if (
          (permissions[to.name] && to.meta.requiresPermission) ||
          to.name == "all"
        ) {
          next();
        } else {
          next("/");
          return;
        }
      } else {
        if (to.name == "all" || (permissions[to.name] && to.meta.userAccess)  ) {
          next();
        } else {
          next("/general-management");
        }
      }
    } else {
      next({
        path: "/auth/login",
      });
    }
  } else {
    if (isLogin && to.matched.some((record) => record.meta.requiresNoAuth)) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  }
});

export default router;
