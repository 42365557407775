const routes = [
  {
    path: "/auth/",
    name: "Auth",
    meta: { requiresNoAuth: true, title: "Auth" },
    component: () => import("../views/auth/Index.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("../views/auth/login/Index.vue"),
        meta: { requiresNoAuth: true, title: "Login" },
      },
    ],
  },
  {
    path: "/",
    name: 'Home',
    meta: { requiresPermission: false,requiresAuth: true },
    component: () => import("../layout/Navbar.vue"),
    children: [
      {
        path: "/general-management",
        name: "all",
        component: () => import("../views/general-management/Index.vue"),
        meta: { requiresPermission: true, requiresAuth: true, title: "General Management" },
      },
      {
        path: "/statistics",
        name: "Users",
        component: () => import("../views/statistics/Index.vue"),
        meta: { requiresPermission: false,requiresAuth: true, title: "Users" },
      },
      {
        path: "/locations",
        name: "locations.read",
        component: () => import("../views/locations/Index.vue"),
        meta: { requiresPermission: true, requiresAuth: true, title: "locations" },
      },
      {
        path: "/tags",
        name: "tags.read",
        component: () => import("../views/tags/Index.vue"),
        meta: { requiresPermission: true, requiresAuth: true, title: "Tags" },
      },
      {
        path: "/announcements",
        name: "announcements.read",
        component: () => import("../views/announcements/Index.vue"),
        meta: { requiresPermission: true, requiresAuth: true, title: "Announcements" },
      },
      {
        path: "/sliders",
        name: "sliders.read",
        component: () => import("../views/sliders/Index.vue"),
        meta: { requiresPermission: true, requiresAuth: true, title: "Sliders" },
      },
      {
        path: "/categories",
        name: "categories.read",
        component: () => import("../views/categories/Index.vue"),
        meta: { requiresPermission: true, requiresAuth: true, title: "Categories" },
      },
      {
        path: "/products",
        name: "products.read",
        component: () => import("../views/products/Index.vue"),
        meta: { requiresPermission: true, requiresAuth: true, title: "Products" },
      },
      {
        path: "/product/add",
        name: "products.create",
        component: () => import("../views/products/add/Index.vue"),
        meta: { userAccess: true, requiresAuth: true, title: "Product create" },
      },
      {
        path: "/product/:id",
        name: "products.edit",
        component: () => import("../views/products/add/Index.vue"),
        meta: { userAccess: true, requiresAuth: true, title: "Product Edit" },
      },
      {
        path: "/coupons",
        name: "campaigns.read",
        component: () => import("../views/coupons/Index.vue"),
        meta: { requiresPermission: true, requiresAuth: true, title: "Categories" },
      },
      {
        path: "/users",
        name: "users.read",
        component: () => import("../views/users/Index.vue"),
        meta: { requiresPermission: true, requiresAuth: true, title: "Users" },
      },
      {
        path: "/settings",
        name: "Settings",
        component: () => import("../views/settings/Index.vue"),
        meta: { requiresPermission: false, requiresAuth: true, title: "Settings" },
      },
    ]
  },
  {
    path: "*",
    redirect: '/auth/login'
  },
];

export default routes;
