<template>
  <div class="base-input input-style relative">
    <v-text-field
      class="w-100"
      :type="show ? 'text': 'password'"
      outlined
      :placeholder="placeholder"
      dense
      height="44px"
      max-height="44px"
      :rules="rules"
      :hide-details="hideDetails"
      hide-spin-buttons
      :value="value"
      @input="$emit('input', $event)"
      :rounded="rounded"
      :prepend-inner-icon="icon"
      full-width
      :readonly="readonly"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      name="input-10-1"
      @click:append="show = !show"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      validator: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [
        (value) => !!value || "Field is required",
        (value) =>
          value && value.length > 7 || "The password must be 8 character at least.",
      ],
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    show: false,
  }),
};
</script>

<style lang="scss">
.input-style {
  .v-input__icon {
    margin-top: 7px;
  }
  .v-input__slot {
    border-radius: 5px;
    fieldset {
      background: #e5ebff6c;
      height: 49px !important;
      box-shadow: 0px 2px 10px rgb(168 168 168 / 5%);
    }
    .v-input__append-inner {
      margin-top: 5px !important;
    }
    .v-text-field__slot {
      height: 44px !important;
    }
  }
  .error--text {
    .v-input__slot {
      fieldset {
        height: 49px !important;
        border: 1px solid #e44358 !important;
      }
    }
  }
}

.input-password {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
</style>
