import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/style/main.scss'
import './assets/style/_variables.scss'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
// components
import BaseSelect from '@/components/base/Select'
import BaseInput from '@/components/base/Input'
import BaseDate from '@/components/base/Date'
import BasePassword from '@/components/base/Password'
import BaseTextarea from '@/components/base/Textarea'
import UploadImage from '@/components/base/UploadImage'
import draggable from "vuedraggable";
import VueClipboard from 'vue-clipboard2'
import JsonExcel from "vue-json-excel";

// audio
import axios from './plugins/axios'


Vue.config.productionTip = false
Vue.component("base-select", BaseSelect);
Vue.component("base-input", BaseInput);
Vue.component("base-date", BaseDate);
Vue.component("base-password", BasePassword);
Vue.component("base-textarea", BaseTextarea);
Vue.component("upload-image", UploadImage);
Vue.component("draggable", draggable);
Vue.component("downloadExcel", JsonExcel);

Vue.use(axios)
Vue.use(VueClipboard)

Vue.mixin({
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isMd() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md
      );
    },
    isLg() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg 
      );
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')






// change picker package